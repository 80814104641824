.btn {
    background-color: $color-black;
    color: $color-1;
    border: none;
    border-radius: 10px;
    font-weight: normal;
    font-size: $font-size-16;
    width: 100%;
    height: 50px;
    transition: all 0.2s ease-in;
    
    &:hover {
        transition: all 0.2s ease-in;
        background-color: $color-white;
        color: $color-black;
        font-weight: bold;
        border: 1px solid $color-black;
    }
}
