footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;

    .footer-wrapper {
        width: 100%;
        padding: 50px 5%;
        @extend .flex;
        @extend .text-color-grey;
        @extend .font-footer;

        .left-side {
            width: 30%;
            .tel {
                h2, a {
                    font-weight: bold;
                    font-size: $font-size-28;
                    color: $color-white;
                    transition: 0.3s;
                }
                a:hover {
                    color: $color-1;
                    transition: 0.3s;
                }
            }
        }
        
        .center-side {
            margin-right: 5%;
            margin-top: 50px;
            height: 135px;
            a.item-footer {
                width: 50%;
                font-weight: bold;
                font-size: $font-size-16;
                color: $color-white;
                text-transform: uppercase;
                transition: 0.3s;
                &:hover {
                    color: $color-1;
                    transition: 0.3s;
                }
            }
        }

        .copyright {
            margin-top: 50px;
            margin-right: 10%;
            font-weight: normal;
            font-size: $font-size-16;
            color: $color-white;
        }

        .right-side {
            margin-top: 50px;
            a.item-footer {
                font-weight: bold;
                font-size: $font-size-16;
                color: $color-white;
                text-transform: uppercase;
                transition: 0.3s;
                &:hover {
                    color: $color-1;
                    transition: 0.3s;
                }
            }
            .paiement {
                h5 {
                    font-weight: normal;
                    font-size: $font-size-16;
                    color: #707070;
                    padding-bottom: 15px;
                }
            }
        }
        @media screen and (max-width: 1840px) {
            .left-side {
                width: 28%;
            }
            .center-side {
                margin-right: 1%;
                a.item-footer {
                    width: 40%;
                }
            }
            @media screen and (max-width: 1660px) {
                .copyright {
                    margin-right: 8%;
                }
                @media screen and (max-width: 1610px) {
                    .left-side {
                        width: 25%;
                        .tel {
                            h2, a {
                                font-size: 1.2rem;
                            }
                        }
                    }
                    @media screen and (max-width: 1540px) {
                        flex-wrap: wrap;
                        .left-side {
                            width: 33%;
                        }
                        .center-side {
                            width: 33%;
                            margin-right: 0;
                        }
                        .copyright {
                            width: 33%;
                            margin-right: 0;
                        }
                        .right-side {
                            margin-top: 10px;
                        }
                        @media screen and (max-width: 1450px) {
                            .left-side {
                                width: 30%;
                            }
                            @media screen and (max-width: 1340px) {
                                .center-side {
                                    width: 37%;
                                }
                                @media screen and (max-width: 1200px) {
                                    flex-direction: column;
                                    .left-side {
                                        width: 100%;
                                    }
                                    .center-side {
                                        margin-right: 0;
                                        height: initial;
                                        width: 100%;
                                        flex-direction: column;
                                        a.item-footer {
                                            width: 100%;
                                            padding-bottom: 25px;
                                        }
                                    }
                                    .copyright {
                                        width: 100%;
                                        margin-right: 0;
                                        margin-top: 25px;
                                    }
                                    .right-side {
                                        margin-top: 0;
                                        .paiement {
                                            margin-top: 50px;
                                        }
                                    }
                                    @media screen and (max-width: $size-xs-max) {
                                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
