// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

a.btn-black {
    background: #030000;
    width: 300px;
    height: 100px;
    border-radius: 10px;
    margin-top: 35px;
    transition: 0.5s;
    h5 {
        font-weight: 500;
        font-size: $font-size-20;
        color: $color-1;
        text-transform: uppercase;
        letter-spacing: 5px;
        transition: 0.5s;
    }
    &:hover {
        background: $color-white;
        border: 1px solid $color-black;
        transition: 0.5s;
        h5 {
            color: #030000;
            transition: 0.5s;
        }
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';




/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-header {
    padding: 50px 0 0 0;
    .left-side {
        width: 35%;
        .text-side {
            padding-left: 16%;
            padding-right: 25px;
            margin-bottom: 35px;
            padding-top: 180px;
            h3 {
                font-weight: bold;
                font-size: $font-size-40;
                color: $color-black;
            }
        }
    }
    .right-side {
        width: 65%;
        margin-left: 10px;
        .text-side {
            padding: 35px;
            .left {
                width: 40%;
                h4 {
                    font-weight: bold;
                    font-size: $font-size-24;
                    color: $color-black;
                    text-transform: uppercase;
                    text-align: right;
                }
            }
            .right {
                width: 57%;
                padding: 0 25px;
            }
        }
    }
    @media screen and (max-width: 1830px) {
        .left-side {
            .text-side {
                h3 {
                    font-size: 2.3vw;
                    br {
                        display: none;
                    }
                }
            }
        }
        .right-side {
            .text-side {
                padding: 75px 35px;
            }
        }
        @media screen and (max-width: 1660px) {
            .left-side {
                .text-side {
                    padding-top: 105px;
                }
            }
            .right-side {
                .text-side {
                    padding: 35px;
                    .left h4 {
                        font-size: 1.1rem;
                    }
                }
            }
            @media screen and (max-width: 1515px) {
                .right-side {
                    .text-side {
                        .left {
                            h4 {
                                br {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1370px) {
                    padding: 0 0 50px 0;
                    flex-direction: column-reverse;
                    .right-side {
                        width: 100%;
                        margin-left: 0;
                        .text-side {
                            padding: 50px 5%;
                            justify-content: space-between;
                        }
                    }
                    .left-side {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        .text-side {
                            margin-bottom: 0;
                            padding-top: 0;
                            padding-left: 5%;
                            padding-right: 50px;
                            width: 50%;
                        }
                    }
                    @media screen and (max-width: 1150px) {
                        .right-side {
                            .text-side {
                                flex-direction: column;
                                .left {
                                    width: 100%;
                                    h4 {
                                        text-align: center;
                                    }
                                }
                                .right {
                                    width: 100%;
                                    padding: 25px 0;
                                    text-align: center;
                                }
                            }
                        }
                        .left-side {
                            flex-direction: column;
                            .text-side {
                                margin-bottom: 35px;
                                padding: 0 5%;
                                width: 100%;
                                text-align: center;
                            }
                        }
                        @media screen and (max-width: $size-sm-max) {
                            .right-side {
                                .text-side {
                                    .left h4, .right h4 {
                                        font-size: 1.5rem;
                                    }
                                }
                            }
                            .left-side {
                                .text-side {
                                    h3 {
                                        font-size: 30px;
                                    }
                                }
                            }
                            @media screen and (max-width: $size-xs-max) {
                                .left-side {
                                    .image-side {
                                        flex-direction: column;
                                        .image:first-child {
                                            margin-bottom: 25px;
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text-image {
    .text-side {
        background: #0000000A;
        width: 35%;
        padding-left: 5%;
        padding-right: 50px;
        h3 {
            font-weight: bold;
            font-size: $font-size-36;
            color: #030000;
            line-height: 1.3;
            padding-bottom: 15px;
        }
    }
    .image-side {
        width: 65%;
        background: url("../images/accueil_section02_img01.jpg") no-repeat;
        background-size: cover;
        background-position: center;
    }
    @media screen and (max-width: 1370px) {
        .text-side {
            h3 {
                font-size: 1.55rem;
                br {
                    display: none;
                }
            }
        }
        @media screen and (max-width: 1150px) {
            flex-direction: column;
            .text-side {
                width: 100%;
                padding: 75px 5%;
                h3 {
                    font-size: 1.8rem;
                }
            }
            .image-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                .image-side {
                    background: url("../images/accueil_section02_img01_m.jpg") no-repeat;
                    background-size: cover;
                }
                .text-side {
                    a.btn-black {
                        width: 240px;
                        height: 80px;
                    }
                }
            }
        }
    }
}

#section-image-text {
    padding: 75px 0;
    margin: 50px 0;
    position: relative;
    .text-side {
        width: 45%;
        padding-left: 50px;
        padding-right: 10%;
        h3 {
            font-weight: bold;
            font-size: $font-size-36;
            color: $color-black;
            line-height: 1.3;
            padding-bottom: 25px;
        }
        a.btn-black {
            width: 270px;
            margin-top: 100px;
        }
    }
    .image-side {
        width: 55%;
    }
    &::before {
        content: '';
        background: #0000000A;
        z-index: -1;
        width: 65%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }
    @media screen and (max-width: 1855px) {
        .text-side {
            padding-right: 5%;
            h3 br {
                display: none;
            }
        }
        @media screen and (max-width: 1150px) {
            flex-direction: column;
            .image-side {
                width: 70%;
                margin-bottom: 50px;
            }
            .text-side {
                padding: 0 5%;
                width: 100%;
            }
            &::before {
                display: none;
            }
            @media screen and (max-width: $size-sm-max) {
                padding: 75px 5%;
                margin: 0;
                .image-side {
                    width: 100%;
                }
                .text-side {
                    padding: 0;
                    a.btn-black {
                        width: 270px;
                        margin-top: 50px;
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    .text-side {
                        text-align: center;
                        a.btn-black {
                            margin: 50px auto 0 auto;
                            width: 230px;
                            height: 80px;
                        }
                    }
                }
            }
        }  
    }
}

/*******************************************************************************
* GROUPE DE MUSIQUE
*******************************************************************************/

#section-intro {
    padding: 50px 5%;
    background: #0000000A;
    margin-top: 50px;
    h2 {
        font-weight: bold;
        font-size: $font-size-60;
        color: #030000;
        text-transform: uppercase;
        padding-bottom: 50px;
    }
    .section-list {
        padding: 0 2%;
        .bloc {
            .text {
                width: 100%;
                h4 {
                    font-weight: 500;
                    font-size: $font-size-20;
                    color: #030000;
                    text-transform: uppercase;
                    line-height: 1.3;
                }
            }
        }
        .left {
            width: 43%;
        }
        .center {
            width: 36%;
        }
    }
    @media screen and (max-width: 1525px) {
        h2 br {
            display: none;
        }
        @media screen and (max-width: 1400px) {
            .section-list {
                .left {
                    width: 47%;
                }
            }
            @media screen and (max-width: 1370px) {
                margin-top: 0;
                @media screen and (max-width: 1220px) {
                    .section-list {
                        flex-wrap: wrap;
                        .left, .center {
                            width: 50%;
                        }
                        .right {
                            padding-top: 10px;
                        }
                    }
                    @media screen and (max-width: 1024px) {
                        h2 {
                            font-size: 2.5rem;
                        }
                        .section-list {
                            .left, .center {
                                width: 50%;
                                padding-right: 10px;
                            }
                        }
                        @media screen and (max-width: $size-xs-max) {
                            .section-list {
                                .left, .center {
                                    width: 100%;
                                    padding-right: 0;
                                }
                                .center {
                                    padding-top: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-images {
    padding: 0 0 25px 5%;
    .left-side {
        width: 30.73%;
    }
    .right-side {
        width: 70%;
    }
    @media screen and (max-width: $size-xs-max) {
        flex-direction: column;
        padding: 0 5% 25px 5%;
        .left-side {
            width: 100%;
            margin: 0 0 15px 0;
        }
        .right-side {
            width: 100%;
        }
    }
}

#section-carousel {
    padding: 50px 5%;
    position: relative;
    h3 {
        font-weight: bold;
        font-size: $font-size-30;
        text-transform: uppercase;
        color: #030000;
        padding: 0 0 35px 35px;
    }
    .main-content {
        .item {
            .col-xs-12.txt {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .owl-nav {
            display: flex;
            align-items: center;
            position: absolute;
            right: 0;
            top: -58px;
            z-index: 1;
            .owl-prev {
                padding-right: 35px;
            }
        }           
    }
    @media screen and (max-width: $size-sm-max) {
        h3 {
            padding: 0 0 35px 0;
        }
        @media screen and (max-width: $size-xs-max) {
            h3 {
                font-size: 1.2rem;
            }
            .main-content .owl-nav {
                top: -52px;
            }
            @media screen and (max-width: 375px) {
                h3 {
                    font-size: 1.1rem;
                }
                .main-content .owl-nav {
                    top: -50px;
                }
            }
        }
    }
}

#section-text-btn {
    padding: 75px 35px;
    background: #0000000A;
    margin: 0 5% 75px 5%;
    .left-side {
        width: 65%;
        margin-right: 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-36;
            text-transform: uppercase;
            color: #030000;
            padding-bottom: 25px;
        }
        p {
            font-size:  $font-size-18;
        }
    }
    .right-side {
        a.btn-black {
            width: 450px;
        }
    }
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        .left-side {
            width: 100%;
            margin: 0 0 25px 0;
        }
        @media screen and (max-width: $size-xs-max) {
            .right-side a.btn-black {
                width: 315px;
                height: 80px;
            }
            @media screen and (max-width: 375px) {
                padding: 75px 15px;
                .right-side a.btn-black {
                    width: 315px;
                }
                @media screen and (max-width: 360px) {
                    .right-side a.btn-black {
                        width: 260px;
                        text-align: center;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* CORPORATIF
*******************************************************************************/

#section-text-image.corporatif {
    margin-top: 50px;
    .text-side a.btn-black {
        width: 370px;
    }
    @media screen and (max-width: 1370px) {
        margin-top: 0;
        @media screen and (max-width: $size-md-max) {
            .text-side a.btn-black {
                width: 295px;
            }
            @media screen and (max-width: 1150px) {
                flex-direction: column-reverse;
                @media screen and (max-width: $size-xs-max) {
                    .text-side a.btn-black {
                        width: 260px;
                    }
                }
            }
        }
    }
}

#section-image-text.corporatif {
    .text-side a.btn-black {
        width: 340px;
    }
    @media screen and (max-width: 1855px) {
        .text-side h3 br {
            display: block;
        }
        @media screen and (max-width: 1150px) {
            .text-side h3 br {
                display: none;
            }
            @media screen and (max-width: $size-xs-max) {
                .text-side {
                    h3 {
                        font-size: 1.7rem;
                    }
                    a.btn-black {
                        width: 265px;
                    }
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnées {
    padding: 100px 5%;
    position: relative;
    margin: -160px 5% 0 5%;
    background: $color-white;
    .left-side {
        width: 55%;
        margin-right: 5%;
        h2 {
            font-weight: bold;
            font-size: $font-size-60;
            color: $color-black;
            text-transform: uppercase;
            padding-bottom: 75px;
        }
        .heures-info {
            .left {
                width: 35%;
                margin-right: 150px;
                h3 {
                    font-weight: bold;
                    font-size: $font-size-38;
                    color: #030000;
                    text-transform: initial;
                    padding-bottom: 5px;
                    border-bottom: 1px solid #030000;
                    margin-bottom: 25px;
                }
                h4 {
                    font-weight: bold;
                    font-size: $font-size-18;
                    color: #030000;
                    line-height: 2.2;
                }
                h5 {
                    font-weight: normal;
                    font-size: $font-size-18;
                    color: #030000;
                    line-height: 2.2;
                }
            }
            .right {
                margin-top: 80px;
                .bloc-info {
                    .icon {
                        width: 18%;
                    }
                    .text {
                        width: 100%;
                        h5 {
                            font-weight: normal;
                            font-size: $font-size-20;
                            color: $color-black;
                        }
                        h4 {
                            font-weight: bold;
                            font-size: $font-size-20;
                            color: $color-black;
                        }
                    }
                }
            }
        }
    }
    .right-side {
        width: 45%;
        box-shadow: 0px 0px 10px 0px #00000029;
        padding: 50px 35px;
        .section-form h3 {
            font-weight: bold;
            font-size: $font-size-40;
            color: $color-black;
            padding-bottom: 35px;
        }
    }
    @media screen and (max-width: 1870px) {
        padding: 100px 4%;
        .left-side {
            .heures-info {
                .left {
                    width: 50%;
                    margin-right: 30px;
                }
            }
        }
        @media screen and (max-width: 1770px) {
            margin: -125px 5% 0 5%;
            .left-side {
                .heures-info {
                    flex-direction: column-reverse;
                    .right {
                        margin: 0 0 75px 0;
                    }
                }
            }
            
                @media screen and (max-width: 1200px) {
                    flex-direction: column;
                    .left-side {
                        width: 100%;
                        margin: 0 0 75px 0;
                        .heures-info .right .bloc-info .icon {
                            width: 10%;
                        }
                    }
                    .right-side {
                        width: 80%;
                        margin: auto;
                    }
                    @media screen and (max-width: $size-sm-max) {
                        margin: -100px 7% 0 7%;
                        padding: 150px 4% 100px 4%;
                        .right-side {
                            width: 100%;
                        }
                        @media screen and (max-width: $size-xs-max) {
                            margin: 0;
                            padding: 75px 5%;
                            .left-side {
                                .heures-info {
                                    .left {
                                        width: 70%;
                                        margin-right: 0;
                                        h4, h5 {
                                            font-size: 1rem;
                                        }
                                    }
                                    .right {
                                        .bloc-info {
                                            .icon {
                                                width: 15%;
                                            }
                                            .text {
                                                h5 {
                                                    font-size: 1.2rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            .right-side {
                                box-shadow: none;
                                padding: 0 0;
                            }
                        }
                    }
                
            }
        }
    }
}

div#content {
    h3#firstHeading {
        font-size: $font-size-28;
        padding-bottom: 10px;
        color: $color-1;
    }
    p a {
        color: $color-black;
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   


}
